function closeAllMatchingModal(filter) {
  [document.getElementsByClassName('_modal-container')]
    .filter(filter)
    .forEach((modals) => {
      [...modals].forEach((modal) => {
        if (!modal.classList.contains('_hidden')) {
          modal.classList.add('_hidden');
        }
      });
    });
}

function closeAllModal() {
  closeAllMatchingModal(() => true);
}

export function setupModal(modals) {
  [...modals].forEach((modal) => {
    const modalId = `modal-${modal.id}`;
    let modalContainer = document.querySelector(
      `div[id="${modalId}"]._modal-container`
    );
    if (!modalContainer) {
      // If we don't have the correct ID, then we're probably the close button.
      // In that case, we should just search our parents, for the modal container.
      modalContainer = modal.closest('_modal-container');
    }

    modal.addEventListener('click', (e) => {
      // Close modals which might be open
      closeAllModal();
      if (modalContainer) {
        modalContainer.classList.toggle('_hidden');
        e.stopPropagation();
        e.preventDefault();
        e.stopImmediatePropagation();
      }
    });
  });
}

function setupModalOnLoad(modals, e) {
  [...modals].forEach((modal) => {
    // Close modals which might be open
    closeAllModal.bind();
    const modalToToggle = document.getElementsByClassName('_modal-container');
    [...modalToToggle].forEach((c) => {
      if (!c.classList.contains('_hidden')) {
        c.classList.add('_hidden');
      }
    });
    if (document.getElementById(`modal-${modal.id}`)) {
      document.getElementById(`modal-${modal.id}`).classList.toggle('_hidden');
      e.stopPropagation();
      e.preventDefault();
      e.stopImmediatePropagation();
    }
  });
}
function initModal(e) {
  [document.getElementsByClassName('_modal-show-trigger')].forEach((modals) => {
    setupModal(modals);
  });
  [document.getElementsByClassName('_modal-hide-trigger')].forEach((modals) => {
    setupModal(modals);
  });
  // When we want to trigger a modal onLoad
  [document.getElementsByClassName('_modal-show-trigger-onload')].forEach(
    (modals) => {
      setupModalOnLoad(modals, e);
    }
  );
}
window.addEventListener('load', (e) => {
  initModal(e);
});

// For content that is lazy loaded via htmx
window.addEventListener('hx-event-init-modal', (e) => {
  initModal(e);
});
